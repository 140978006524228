// const backendURL = "http://localhost:3001"
// const frontendURL = "http://localhost:3000"
// const selfURL = "http://localhost:8602"
const backendURL = "https://backend.educobot.com"
const frontendURL = "https://educobot.com"
const selfURL = "https://editor.blockly.educobot.com"
module.exports={
    backendURL,
    frontendURL,
    selfURL
}