import React, { useEffect, useState } from 'react'
import ContentDialogBox from '../../react_components/dialog_box/ContentDialogBox'
import { extractURL } from '../../backend-ajax/backend';
import { backendURL, frontendURL, selfURL } from '../../backend-ajax/config';
import { Button, Card } from '@mui/material';
import SubmitSuccess from './submit-success';
import NotificationMessages from '../../react_components/dialog_box/SuccessMessages';

function StudentSubmissionComponent({ lesson, user, SavingHander }) {
    const [isOpen, setIsOpen] = useState(false)
    const [lessonData, setLessonData] = useState(lesson.data)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [reviewFunction, setReviewFunction] = useState(null)
    const [submissionData, setSubmissionData] = useState(null)
    const [notification, setNotification] = useState({
        isVisible: false,
        title: "Creation Successful",
        image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
        description: "Please wait, new window is going to be opened"
    })
    async function saveProgress() {
        if (!!user) {
            try {
                const new_name = lessonData._id + user.user.euUserId + ".sb3"
                console.log("new_name", new_name);

                const AWS_URL = await SavingHander(new_name)
                console.log("AWS_URL", AWS_URL);
                setNotification({
                    isVisible: true,
                    title: "Assignment submitted Successfully",
                    image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
                    description: "Assignment submitted successfully, Please wait, we are saving your data"
                })
                setTimeout(() => {
                    setNotification({
                        isVisible: false,
                        title: "Assignment submitted Successfully",
                        image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
                        description: "Assignment submitted successfully, Please wait, we are saving your data,"
                    })
                }, 3000)
            } catch (error) {
                console.log(error);
            }
        }
    }

    async function SubmitLesson() {
        if (!!user) {
            try {
                const new_name = lessonData._id + user.user.euUserId + ".sb3"
                console.log("new_name", new_name);

                const AWS_URL = await SavingHander(new_name)
                console.log("AWS_URL", AWS_URL);
                const response = await fetch(`${backendURL}/blockly/SubmitAssignmentAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "Application/json"
                    },
                    body: JSON.stringify({
                        lessonId: lessonData._id,
                        studentId: user.user.euUserId,
                        fileName: "https://open-editor-assets.s3.ap-south-1.amazonaws.com/playgrounds/" + new_name + ".x.scratch.sb3",
                    })
                })
                const data = await response.json()
                if (!!data) {
                    console.log(data);
                    // alert("Submitted Successfully")
                    setSubmissionData(data)
                    setNotification({
                        isVisible: true,
                        title: "Assignment Saved Successfully",
                        image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
                        description: "Assignment Saved successfully, Please wait, we are saving your data"
                    })
                    setTimeout(() => {
                        window.location.href = selfURL + "/?&user_id=" + user.user.euUserId + "&submission_id=" + data._id
                        setNotification({
                            isVisible: false,
                            title: "Assignment Saved Successfully",
                            image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
                            description: "Assignment Saved successfully, Please wait, we are saving your data,"
                        })
                    }, 10000)
                } else {
                    setNotification({
                        isVisible: true,
                        title: "Can't be Saved",
                        image: "https://cdn-icons-png.freepik.com/256/1770/1770579.png?semt=ais_hybrid",
                        description: "Not able to save the Assignment"
                    })
                    setTimeout(() => {
                        setNotification({
                            isVisible: false,
                            title: "Can't be Saved",
                            image: "https://cdn-icons-png.freepik.com/256/1770/1770579.png?semt=ais_hybrid",
                            description: "Not able to save the Assignment"
                        })
                        handleClose()
                    }, 3000)
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
    async function FinalSubmitLesson() {
        if (!!user) {
            try {
                if (!!lesson && !lesson.submission) {
                    await SubmitLesson()
                }
                else {
                    const new_name = lessonData._id + user.user.euUserId + ".sb3"
                    console.log("new_name", new_name);

                    const AWS_URL = await SavingHander(new_name)

                    console.log("AWS_URL", AWS_URL);
                }
                const response = await fetch(`${backendURL}/blockly/FinalSubmitAssignmentAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "Application/json"
                    },
                    body: JSON.stringify({
                        lessonId: lessonData._id,
                        studentId: user.user.euUserId,
                    })
                })
                const data = await response.json()
                if (!!data) {
                    console.log(data);
                    setNotification({
                        isVisible: true,
                        title: "Assignment submitted Successfully",
                        image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
                        description: "Assignment submitted successfully, Please wait, we are saving your data"
                    })
                    setTimeout(() => {
                        setIsSubmitted(true)
                    }, 2000)
                    setTimeout(() => {
                        setNotification({
                            isVisible: false,
                            title: "Assignment submitted Successfully",
                            image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
                            description: "Assignment submitted successfully, Please wait, we are saving your data,"
                        })
                        setIsSubmitted(true)
                    }, 10000)
                } else {
                    setNotification({
                        isVisible: true,
                        title: "Can't be Saved",
                        image: "https://cdn-icons-png.freepik.com/256/1770/1770579.png?semt=ais_hybrid",
                        description: "Not able to save the Assignment"
                    })
                    setTimeout(() => {
                        setNotification({
                            isVisible: false,
                            title: "Can't be Saved",
                            image: "https://cdn-icons-png.freepik.com/256/1770/1770579.png?semt=ais_hybrid",
                            description: "Not able to save the Assignment"
                        })
                        handleClose()
                    }, 3000)
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
    return (
        <div>
            <Button
                size='small'
                variant="contained"
                color="primary"
                onClick={() => {
                    setIsOpen(true)
                }}
                style={{
                    textTransform: "none",
                    margin: "0 10px"
                }}
            >
                Submit
            </Button>
            <Button
                size='small'
                variant="contained"
                color="secondary"
                onClick={() => {
                    if (!!lesson && lesson.submission) {
                        saveProgress()
                    } else {
                        SubmitLesson()
                    }
                }}
                style={{
                    textTransform: "none",
                    margin: "0 10px"
                }}
            >
                Save
            </Button>
            {
                notification.isVisible && <NotificationMessages
                    isVisible={notification.isVisible}
                    title={notification.title}
                    image={notification.image}
                    description={notification.description}
                />
            }
            <ContentDialogBox
                DialogContainerStyle={{
                    borderRadius: "30px",
                    background: "#EEFF22",
                    color: "#80000B",
                    fontweight: "bolder",
                    textAlign: "center"
                }}
                maxWidth='md'
                isOpen={isOpen}
                onClose={() => {  }}
                content={<>
                    <h1>Submit This Assignment</h1>
                    <hr style={{ border: "1px solid #80000B" }} />
                    <h3>{
                        lessonData && lessonData.PlaygroundName
                    }</h3>
                    <p>
                        {
                            lessonData && lessonData.PlaygroundDescription
                        }
                    </p>
                    <hr style={{ border: "1px solid #80000B" }} />
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "50px"
                    }}>
                        Your submission is in review, and your teacher will assign coins based on your performance.
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={() => { setIsOpen(false) }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => { FinalSubmitLesson() }} style={{
                                marginLeft: "20px"
                            }}
                        >
                            Submit
                        </Button>
                    </div>


                </>}
                isTransition={true}
                transitionDirection="down"
            />
            {
                isSubmitted && <SubmitSuccess data={
                    submissionData
                } title={"Submitted Successfully"} subtitle={""} />
            }
        </div>
    )
}

export default StudentSubmissionComponent