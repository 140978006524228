import React, { useState } from 'react'
import ContentDialogBox from '../../react_components/dialog_box/ContentDialogBox'
import { Button, Card, CardHeader, CardMedia } from '@mui/material'

function Examples() {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Button variant='contained' size='small' color='warning' onClick={() => { setOpen(!open) }} style={{
                textTransform: "none",
                margin: "0 10px"
            }}>Examples</Button>
            <ContentDialogBox
                DialogContainerStyle={{
                    // borderRadius: "30px",
                    background: "#264653",
                    color: "#FFF",
                    fontweight: "bolder",
                    textAlign: "center"
                }}
                isOpen={open}
                onClose={() => { setOpen(false) }}
                title={'Examples'}
                transitionDirection='down'
                isTransition={true}
                maxWidth='xl'
                fullScreen
                content={<div style={{
                    minHeight: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap"
                }}>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                    <Card style={{
                        width: "200px",
                        minHeight: "150px",
                        margin: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'flex-start',
                        alignItems: "center",
                        borderRadius: "10px"
                    }}>
                        <img src='https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/Screenshot+2024-05-28+054606.png' style={{
                            width: "200px",
                        }} />
                        <div style={{
                            padding: "10px",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "0px"
                            }}>Example</h4>
                            <Button size='small' variant='outlined' fullWidth style={{
                                borderRadius: "10px",
                                textTransform: "none",
                                padding: "0px",
                                marginTop: "10px",
                                fontWeight: "bolder"
                            }}>Open</Button>
                        </div>
                    </Card>
                </div>}
            />

        </>
    )
}

export default Examples