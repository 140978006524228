import ReactS3Client from 'react-aws-s3-typescript';
// import S3 from 'aws-sdk/clients/s3';
import AWS from 'aws-sdk'; // Import entire SDK (optional)


const AWS_BUCKET_NAME = 'open-editor-assets'
const AWS_REGION = 'ap-south-1'
const AWS_ACCESS_ID = "AKIATV35CQSBFYBN5L5U"
const AWS_SECRET_ACCESS_ID = "Z922fhDbb6tn9dsshIINWUTHSZy9INbtT6kKtFNr"
const AWS_DIRECTORY = 'teacher-playgrounds'
const S3_BUCKET = "open-editor-assets"; 
const REGION = "ap-south-1"; 
AWS.config.update({
    accessKeyId: "AKIATV35CQSBFYBN5L5U",
    secretAccessKey: "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",
});



const s3Fileconfig = {
    bucketName: 'open-editor-assets',
    dirName: 'playgrounds', 
    region: 'ap-south-1',
    accessKeyId: 'AKIATV35CQSBFYBN5L5U',
    secretAccessKey: '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',
}
const thumbnailFileconfig = {
    bucketName: 'open-editor-assets',
    dirName: 'thumbnails', 
    region: 'ap-south-1',
    accessKeyId: 'AKIATV35CQSBFYBN5L5U',
    secretAccessKey: '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',
}


export async function uploadFileOnS3(file, filename) {

    // const s3 = new S3({
    //     params: { Bucket: S3_BUCKET },
    //     region: REGION,
    // });
    // const params = {
    //     Bucket: S3_BUCKET,
    //     Key: filename,
    //     Body: file,
    // };
    try {
        const s3 = new ReactS3Client(s3Fileconfig);
        try {
            const res = await s3.uploadFile(file, filename);
            console.log(res);
            
            if(!res) return false
            return res;
        } catch (exception) {
            console.log(exception);
            return false
        }
    
        // const upload = await s3.putObject(params).promise();
        // console.log(upload);
        // alert("File Saved successfully.");

    } catch (error) {
        console.error(error);
        // alert("Error uploading file: " + error.message); // Inform user about the error
    }
};

export async function uploadThumbnailOnS3(file, filename) {
    // const s3 = new S3({
    //     params: { Bucket: S3_BUCKET },
    //     region: REGION,
    // });
    // const params = {
    //     Bucket: S3_BUCKET,
    //     Key: filename,
    //     Body: file,
    // };

    try {
        const s3 = new ReactS3Client(thumbnailFileconfig);
        try {
            const res = await s3.uploadFile(file, filename);
            console.log(res);
            
            if(!res) return null
            return res;
        } catch (exception) {
            console.log(exception);
            return null
        }
        // const upload = await s3.putObject(params).promise();
        // console.log(upload);
        // // alert("File uploaded successfully.");
        // return upload

    } catch (error) {
        console.error(error);
        // alert("Error uploading file: " + error.message); // Inform user about the error
        return null
    }
}