import React, { useEffect, useState } from 'react'
import Modal from '../../containers/modal'
import './project-saving-modal.css'
import {backendURL} from '../../backend-ajax/config'
import { extractURL } from '../../backend-ajax/backend'
import { uploadThumbnailOnS3 } from '../../backend-ajax/S3'
import ContentDialogBox from '../../react_components/dialog_box/ContentDialogBox'
import NotificationMessages from '../../react_components/dialog_box/SuccessMessages'
import { Button } from '@mui/material'


function SaveButton({ SavingHander }) {
    const [open, setOpen] = useState(true)
    const [instructions, setInstructions] = React.useState([])
    const [projectName, setProjectName] = React.useState('')
    const [projectDescription, setProjectDescription] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [user, setUser] = useState(localStorage.getItem('user'))
    const [isEditing, setIsEditing] = React.useState(false)
    const [lessonData, setLessonData] = useState({})
    const [thumbnailURL, setThumbnailURL] = useState("")
    const [lessonId, setLessonId] = useState()
    const [lessonType, setLessonType] = useState("debug")
    const [notification, setNotification] = useState({
        isVisible: false,
        title: "Creation Successful",
        image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
        description: "Please wait, new window is going to be opened"
    })
     
    useEffect(() => {
        setLessonId(extractURL("lesson_id"))
        !!extractURL("lesson_id") && setIsEditing(true)
        if (!user) {
            // handleClose()
        } else {
            user && setUser(JSON.parse(user))
        }
    }, [])
    async function updateData() {
        let user=JSON.parse(localStorage.getItem('user'))
        if (!lessonId) return
        if (!projectName) {
            alert("Please Enter Project Name")
            setIsLoading(false)
            return
        }
        if (!projectDescription) {
            alert("Please Enter Project Description")
            setIsLoading(false)
            return
        }
        try {
            const new_name = projectName + user.user.euUserId + ".sb3"
            const AWS_URL = await SavingHander(new_name)
            console.log("AWS_URL", AWS_URL);
            const response = await fetch(`${backendURL}/blockly/update-lesson-details`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    lessonId,
                    name: projectName,
                    description: projectDescription,
                    url: "https://open-editor-assets.s3.ap-south-1.amazonaws.com/playgrounds/" + new_name+".x.scratch.sb3",
                    teacherId: user.user.euUserId,
                    instructions: instructions,
                    thumbnailURL: thumbnailURL,
                    type: lessonType
                })
            })
            if (response.status === 200) {
                setNotification({
                    isVisible: true,
                    title: "Assignment Updated Successfull",
                    image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
                    description: "Assignment updated successfully, Please wait, we are saving your data"
                })
                setTimeout(() => {
                    setNotification({
                        isVisible: false,
                        title: "Assignment Updated Successfull",
                        image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
                        description: "Assignment updated successfully, Please wait, we are saving your data,"
                    })
                }, 10000)
            }else{
                setNotification({
                    isVisible: true,
                    title: "Can't be Saved",
                    image: "https://cdn-icons-png.freepik.com/256/1770/1770579.png?semt=ais_hybrid",
                    description: "Not able to save the project"
                })
                setTimeout(() => {
                    setNotification({
                        isVisible: false,
                        title: "Can't be Saved",
                        image: "https://cdn-icons-png.freepik.com/256/1770/1770579.png?semt=ais_hybrid",
                        description: "Not able to save the project"
                    })
                    handleClose()
                }, 3000)
            }
        } catch (error) {
            console.log(error);
            setNotification({
                isVisible: true,
                title: "Can't be Saved",
                image: "https://cdn-icons-png.freepik.com/256/1770/1770579.png?semt=ais_hybrid",
                description: "Not able to save the project"
            })
            setTimeout(() => {
                setNotification({
                    isVisible: false,
                    title: "Can't be Saved",
                    image: "https://cdn-icons-png.freepik.com/256/1770/1770579.png?semt=ais_hybrid",
                    description: "Not able to save the project"
                })
                handleClose()
            }, 3000)
        }
    }
    async function getLessonData() {
        try {
            console.log(window.location.href);
            const lessonId = extractURL("lesson_id");
            const studentId = extractURL("user_id");
            console.log(lessonId);
            if (!!lessonId) {
                const response = await fetch(`${backendURL}/blockly/get-lesson-details`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "Application/json"
                    },
                    body: JSON.stringify({
                        lessonId,
                        studentId
                    })
                })
                const data = await response.json()
                if (!!data && !!data.data) {
                    setThumbnailURL(data.data.thumbnailURL)
                    setProjectName(data.data.PlaygroundName)
                    setProjectDescription(data.data.PlaygroundDescription)
                    setInstructions(data.data.instructions)
                    setLessonType(data.data.type)
                }
                console.log(data.data);
                setLessonData(data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getLessonData()
    }, [lessonId])
    return (
        <>
            {
                notification.isVisible && <NotificationMessages
                    isVisible={notification.isVisible}
                    title={notification.title}
                    image={notification.image}
                    description={notification.description}
                />
            }
            <Button variant='contained' size='small' color='success' onClick={() => {

                updateData()

            }} style={{
                textTransform: "none",
                margin: "0 10px"
            }} >Save</Button>

        </>
    )
}

export default SaveButton