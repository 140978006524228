import React, { useEffect, useState } from 'react'

import ContentDialogBox from '../../react_components/dialog_box/ContentDialogBox'
import NotificationMessages from '../../react_components/dialog_box/SuccessMessages'
import { Box, Button, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import countryCodeArray from './country-codes';
import { toast } from 'react-toastify';
import {backendURL} from '../../backend-ajax/config';
import { extractURL } from '../../backend-ajax/backend';




function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function LoginPage() {
    const [open, setOpen] = useState(false)
    const [email, setEmail] = useState("")
    const [counryCode, setCountryCode] = useState("+91")
    const [password, setPassword] = useState("")
    const [currentSelectedType, setCurrentSelectedType] = useState(0)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        setTimeout(() => {
            const user = JSON.parse(localStorage.getItem("user"))
            console.log("user", user)
            if (!!user) {
                loginUser(user.user.userID)
            } else {
                setOpen(true)
            }
        }, 5000)
    }, [])


    

    function loginUserWithEmail() {
        if (email === "" && password === "") {
            toast.error("Please Enter Credentials", "top")
            return
        }
        LoginUserEmailAPICall()
    }

    async function loginUser(userId) {
        setIsLoading(true)

        if (!!userId) {
            const response = await fetch(`${backendURL}/blockly/get-user-details`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    userId
                })
            })
            const data = await response.json()
            if (!!data && data.user && data.user.euName.euName) {
                const full_name = data.user.euName
                localStorage.setItem("user", JSON.stringify(data))
                if (!!full_name) {
                    document.getElementById("user_name").innerHTML = full_name
                    setOpen(false)
                }
            }

            const response2 = await fetch(`${backendURL}/uploadPIImageRoute/getProfileImageByPath`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    userID: userId
                })
            })
            const profileURL = await response2.text()
            console.log("profileURL", profileURL);
            if (!!profileURL && !!data) {
                localStorage.setItem("profileURL", profileURL)
                document.getElementById('profile-image').src = profileURL
                try {
                    const response10 = await fetch(profileURL)
                    if (response10.status === 200) {
                    } else {
                        document.getElementById('profile-image').src = "https://ui-avatars.com/api/?name=" + data.user.euName
                    }
                } catch (error) {
                    document.getElementById('profile-image').src = "https://ui-avatars.com/api/?name=" + data.user.euName
                    console.log(error);
                }
            }
            else {
                alert("User Id not found")
                window.location.href = "https://educobot.com"
            }
        }


        setIsLoading(false)
    }

    async function loginUserWithPhone() {
        if (phoneNumber === "" && password === "") {
            toast.error("Please Enter Credentials", "top")
            return
        }
        if (
            phoneNumber.trim().length > 10 ||
            phoneNumber.trim().length < 9

        ) {
            toast.error("please enter valid phone number", "top")
            return;
        }
        LoginUserPhoneAPICall()
    }

    async function LoginUserEmailAPICall() {
        setIsLoading(true)
        try {
            const response = await fetch(`${backendURL}/users/getUser`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    email, password
                })
            })
            let result = await response.json()
            console.log(result);
            console.log(result.user);
            if (!!result.user) {
                await loginUser(result.user) 
                extractURL("lesson_id")&& (window.location.href = "/?user_id=" + result.user + "&lesson_id=" + extractURL("lesson_id"))

                toast.success("Logged In Successfully", "top")
            } else {
                toast.warn("Invalid Credentials", "top")
            }
        } catch (error) {
            console.log(error);

            toast.warn("Some error occurred", "top")
        }
        setIsLoading(false)
    }
    async function LoginUserPhoneAPICall() {
        setIsLoading(true)
        try {
            const response = await fetch(`${backendURL}/users/loginWithPhoneNumber`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    mobileCode: countryCode,
                    phone: phoneNumber,
                    password
                })
            })
            let result = await response.json()
            // console.log(result[0]);
            if (!!result && result.length > 0) {
                await loginUser(result.user)
                extractURL("lesson_id")&& (window.location.href = "/?user_id=" + result.user + "&lesson_id=" + extractURL("lesson_id"))
                toast.success("Logged In Successfully", "top")
            } else {
                toast.warn("Invalid Credentials", "top")
            }
        } catch (error) {
            console.log(error);
            toast.warn("Some error occurred", "top")
        }
        setIsLoading(false)
    }

    return (
        <div>
            <ContentDialogBox
                isOpen={open}
                onClose={
                    () => { }
                }
                isTransition={true}
                transitionDirection='right'
                maxWidth='sm'
                title="eduCOBOT Login"
                content={
                    <>
                        <div>



                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={currentSelectedType} onChange={(event, newValue) => {
                                    setCurrentSelectedType(newValue);
                                }} aria-label="basic tabs example">
                                    <Tab label="Login with Email" {...a11yProps(0)} sx={{
                                        textTransform: "none"
                                    }} />
                                    <Tab label="Login with Phone" {...a11yProps(1)} sx={{
                                        textTransform: "none"
                                    }} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={currentSelectedType} index={0}>
                                <>
                                    <Typography>
                                        <TextField
                                            fullWidth
                                            type="email"
                                            name='email'
                                            label="Email"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                            }}
                                            placeholder='Enter Email'
                                        />
                                        <TextField
                                            fullWidth
                                            type="password"
                                            name='password'
                                            label="Pasword"
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value)
                                            }}
                                            placeholder='Enter Password'
                                            sx={{
                                                margin: "20px 0"
                                            }}
                                        />
                                        <Button fullWidth variant='contained' onClick={loginUserWithEmail}>
                                            Login
                                        </Button>
                                    </Typography>
                                </>

                            </CustomTabPanel>
                            <CustomTabPanel value={currentSelectedType} index={1}>
                                <>
                                    <Typography>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Access"
                                                defaultValue={10}
                                                value={counryCode}
                                                sx={{
                                                    margin: "3px",
                                                    padding: "0",
                                                    maxWidth: "200px"
                                                }}
                                            >
                                                {
                                                    countryCodeArray.map((item, index) => {
                                                        return <MenuItem value={item.dial_code} key={index} onClick={() => {
                                                            setCountryCode(item.dial_code)
                                                        }} >{item.name}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                name='phone'
                                                label="Enter Phone"
                                                value={phoneNumber}
                                                onChange={(e) => {
                                                    setPhoneNumber(e.target.value)
                                                }}
                                                placeholder='Enter Phone'
                                            />
                                        </div>
                                        <TextField
                                            fullWidth
                                            type="password"
                                            name='password'
                                            label="Pasword"
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value)
                                            }}
                                            placeholder='Enter Password'
                                            sx={{
                                                margin: "20px 0"
                                            }}
                                        />
                                        <Button fullWidth variant='contained' onClick={loginUserWithPhone}>
                                            Login
                                        </Button>
                                    </Typography>
                                </>
                            </CustomTabPanel>

                        </div>
                    </>
                }


            />

        </div>
    )
}

export default LoginPage