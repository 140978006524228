import React, { useEffect, useState } from 'react'
import Modal from '../../containers/modal'
import './project-saving-modal.css'
import {backendURL} from '../../backend-ajax/config'
import { extractURL } from '../../backend-ajax/backend'
import { uploadThumbnailOnS3 } from '../../backend-ajax/S3'
import ContentDialogBox from '../../react_components/dialog_box/ContentDialogBox'
import NotificationMessages from '../../react_components/dialog_box/SuccessMessages'
function ProjectSavingModalStudent({ handleClose, SavingHander }) {
    const [instructions, setInstructions] = React.useState([])
    const [projectName, setProjectName] = React.useState('')
    const [projectDescription, setProjectDescription] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [user, setUser] = useState(localStorage.getItem('user'))
    const [isEditing, setIsEditing] = React.useState(false)
    const [lessonData, setLessonData] = useState({})
    const [thumbnailURL, setThumbnailURL] = useState("")
    const [lessonId, setLessonId] = useState()
    const [lessonType, setLessonType] = useState("practice")
    const [notification, setNotification] = useState({
        isVisible: false,
        title: "Creation Successful",
        image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
        description: "Please wait, new window is going to be opened"
    })
    useEffect(() => {
        setLessonId(extractURL("lesson_id"))
        !!extractURL("lesson_id") && setIsEditing(true)
        if (!user) {
            // handleClose()
        } else {
            user && setUser(JSON.parse(user))
        }
    }, [])

    useEffect(() => {
        getLessonData()
    }, [lessonId])

    async function updateData(e) {
        e.preventDefault()
        if (!lessonId) return
        if (!projectName) {
            alert("Please Enter Project Name")
            setIsLoading(false)
            return
        }
        if (!projectDescription) {
            alert("Please Enter Project Description")
            setIsLoading(false)
            return
        }
        try {
            const new_name = projectName + user.user.euUserId + ".sb3"
            const AWS_URL = await SavingHander(new_name)
            console.log("AWS_URL", AWS_URL);
            const response = await fetch(`${backendURL}/blockly/update-lesson-details`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    lessonId,
                    name: projectName,
                    description: projectDescription,
                    url: "https://open-editor-assets.s3.ap-south-1.amazonaws.com/playgrounds/" + new_name+".x.scratch.sb3",
                    teacherId: user.user.euUserId,
                    instructions: instructions,
                    thumbnailURL: thumbnailURL,
                    type: lessonType
                }),
            })
            
        } catch (error) {
            console.log(error);
        }
    }


    async function getLessonData() {
        try {
            console.log(window.location.href);
            const lessonId = extractURL("lesson_id");
            const studentId = extractURL("user_id");

            console.log(lessonId);
            if (!!lessonId) {
                const response = await fetch(`${backendURL}/blockly/get-lesson-details`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "Application/json"
                    },
                    body: JSON.stringify({
                        lessonId,
                        studentId
                    })
                })
                const data = await response.json()
                if (!!data && !!data.data) {
                    setThumbnailURL(data.data.thumbnailURL)
                    setProjectName(data.data.PlaygroundName)
                    setProjectDescription(data.data.PlaygroundDescription)
                    setInstructions(data.data.instructions)
                    setLessonType(data.data.type)
                }
                console.log(data.data);
                setLessonData(data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function uploadFileOnS3(file) {
        console.log("file", file.name);
        const new_name = projectName + user.user.euUserId +lessonId + "." + file.name.split(".")[file.name.split(".").length - 1]
        const upload = await uploadThumbnailOnS3(file, new_name)
        console.log(upload);
        console.log("new_name", new_name);
        setThumbnailURL(upload.location)
        return new_name
    }

    useEffect(() => {
        if (user) {
            setUser(JSON.parse(user))
        }
    }, [])
    async function handleSubmit(e) {
        e.preventDefault()
        setIsLoading(true)
        console.log(user);
        if (!projectName) {
            alert("Please Enter Project Name")
            setIsLoading(false)
            return
        }
        if (!projectDescription) {
            alert("Please Enter Project Description")
            setIsLoading(false)
            return
        }
        try {
            const new_name = projectName + user.user.euUserId + ".sb3"
            const AWS_URL = await SavingHander(new_name)
            console.log("AWS_URL", AWS_URL);
            const response = await fetch(`${backendURL}/blockly/SaveStudentAssignmentCreationDataAPI`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: projectName,
                    description: projectDescription,
                    url: "https://open-editor-assets.s3.ap-south-1.amazonaws.com/playgrounds/" + new_name+".x.scratch.sb3",
                    studentId: user.user.euUserId,
                    thumbnailURL: thumbnailURL,
                }),
            })
            const result = await response.json()
            console.log(result);
            if (response.status===200 && !!result._id ) {
                setNotification({
                    isVisible: true,
                    title: "Project Saved Successfully",
                    image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
                    description: "Please wait, we are saving your data"
                })
                setTimeout(() => {
                    setNotification({    isVisible: false,
                        title: "Project Saved Successfully",
                        image: "https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png",
                        description: "Please wait, we are saving your data,"
                    })
                    handleClose()
                    window.location.href = "/?user_id=" + user.user.euUserId + "&lesson_id=" + result._id
                }, 10000)
            }else{
                setNotification({
                    isVisible: true,
                    title: "Project can't be Saved",
                    image: "https://cdn-icons-png.freepik.com/256/1770/1770579.png?semt=ais_hybrid",
                    description: "your are not allowed to save this project, limit of 3 projects exceeded"
                })
                setTimeout(() => {
                    setNotification({
                        isVisible: false,
                        title: "Project can't be Saved",
                        image: "https://cdn-icons-png.freepik.com/256/1770/1770579.png?semt=ais_hybrid",
                        description: "your are not allowed to save this project, limit of 3 projects exceeded"
                    })
                    handleClose()
                }, 3000)
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)
    }

    return (
        <div>      {
                notification.isVisible && <NotificationMessages
                    isVisible={notification.isVisible}
                    title={notification.title}
                    image={notification.image}
                    description={notification.description}
                />
            }
            <Modal
                fullScreen
                contentLabel={isEditing ? "Edit Assignment" : "Create Assignment"}
                id={"this.props.id"}
                onRequestClose={handleClose}
            >

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <label title='Kindly provide a screenshot of your project as your thumbnail.' htmlFor='thumbnail' style={{
                            minWidth: "250px",
                            minHeight: "150px",
                            border: "1px dashed #00000055",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            cursor: "pointer",
                            backgroundColor: "#ffffff",
                            margin: "10px",
                            padding: "10px",
                        }}>
                            {
                                thumbnailURL.length > 5 ?
                                    <img src={thumbnailURL} style={{
                                        width: "200px",
                                    }} alt="" /> :
                                    <img src={"https://www.bakemyweb.com/files/public/de/69/2770cae8aabc7706c58dde69/i/af/bc/64f1b7f4c4d470001e9aafbc/original?name=24.gif-upload.gif&mimetype=image/gif&cd=inline"} style={{
                                        width: "200px",
                                    }} alt="" />
                            }
                            <p>Upload Thumbnail</p>
                        </label>
                        <input type="file" name="thumbnail" id="thumbnail" style={{
                            display: "none"
                        }} onChange={(event) => {
                            console.log(event.target.files);
                            event.target.files && uploadFileOnS3(event.target.files[0])
                        }} accept="image/png, image/jpg, image/jpeg" />
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <p>Project Info</p>
                        <input
                            type='text'
                            name='name'
                            id='name'
                            placeholder='Project Name'
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            style={{
                                width: '100%',
                                maxWidth: "800px",
                                fontSize: "15px",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "none",
                                margin: "10px",
                                outline: "none",
                            }}
                        />
                        <textarea
                            name='description'
                            id='description'
                            placeholder='Project Description'
                            value={projectDescription}
                            onChange={(e) => setProjectDescription(e.target.value)}
                            style={{
                                width: '100%',
                                maxWidth: "800px",
                                fontSize: "15px",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "none",
                                margin: "10px",
                                outline: "none"
                            }}
                        />
                    </div>
                    <div style={{
                        width: '100%',
                        maxWidth: "800px",
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>

                        <button
                            onClick={handleClose}
                            style={{
                                padding: "6px 30px",
                                outline: "none",
                                border: "1px solid #FFFFFF95",
                                background: "coral",
                                borderRadius: "5px",
                                color: "white",
                                cursor: "pointer"
                            }}
                        >Close</button>
                        <button
                            onClick={(e) => {
                                if (isEditing) {
                                    updateData(e)
                                } else {
                                    handleSubmit(e)
                                }
                            }}
                            style={{
                                padding: "6px 30px",
                                outline: "none",
                                border: "1px solid #FFFFFF95",
                                background: "green",
                                borderRadius: "5px",
                                color: "white",
                                cursor: "pointer"
                            }}

                        >Save</button>

                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default ProjectSavingModalStudent