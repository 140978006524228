const countryCodeArray = [
    {
        name: "Andorra +376",
        dial_code: "+376",
        code: "AD",
    },
    {
        name: "United Arab Emirates +971",
        dial_code: "+971",
        code: "AE",
    },
    {
        name: "Afghanistan +93",
        dial_code: "+93",
        code: "AF",
    },
    {
        name: "Antigua and Barbuda +1268",
        dial_code: "+1268",
        code: "AG",
    },
    {
        name: "Anguilla +1264",
        dial_code: "+1264",
        code: "AI",
    },
    {
        name: "Albania +355",
        dial_code: "+355",
        code: "AL",
    },
    {
        name: "Armenia +374",
        dial_code: "+374",
        code: "AM",
    },
    {
        name: "Netherlands Antilles +599",
        dial_code: "+599",
        code: "AN",
    },
    {
        name: "Angola +244",
        dial_code: "+244",
        code: "AO",
    },
    {
        name: "Antarctica +672",
        dial_code: "+672",
        code: "AQ",
    },
    {
        name: "Argentina +54",
        dial_code: "+54",
        code: "AR",
    },
    {
        name: "AmericanSamoa +1684",
        dial_code: "+1684",
        code: "AS",
    },
    {
        name: "Austria +43",
        dial_code: "+43",
        code: "AT",
    },
    {
        name: "Australia +61",
        dial_code: "+61",
        code: "AU",
    },
    {
        name: "Aruba +297",
        dial_code: "+297",
        code: "AW",
    },
    {
        name: "Aland Islands +358",
        dial_code: "+358",
        code: "AX",
    },
    {
        name: "Azerbaijan +994",
        dial_code: "+994",
        code: "AZ",
    },
    {
        name: "Bosnia and Herzegovina +387",
        dial_code: "+387",
        code: "BA",
    },
    {
        name: "Barbados +1246",
        dial_code: "+1246",
        code: "BB",
    },
    {
        name: "Bangladesh +880",
        dial_code: "+880",
        code: "BD",
    },
    {
        name: "Belgium +32",
        dial_code: "+32",
        code: "BE",
    },
    {
        name: "Burkina Faso +226",
        dial_code: "+226",
        code: "BF",
    },
    {
        name: "Bulgaria +359",
        dial_code: "+359",
        code: "BG",
    },
    {
        name: "Bahrain +973",
        dial_code: "+973",
        code: "BH",
    },
    {
        name: "Burundi +257",
        dial_code: "+257",
        code: "BI",
    },
    {
        name: "Benin +229",
        dial_code: "+229",
        code: "BJ",
    },
    {
        name: "Saint Barthelemy +590",
        dial_code: "+590",
        code: "BL",
    },
    {
        name: "Bermuda +1441",
        dial_code: "+1441",
        code: "BM",
    },
    {
        name: "Brunei Darussalam +673",
        dial_code: "+673",
        code: "BN",
    },
    {
        name: "Bolivia, Plurinational State of +591",
        dial_code: "+591",
        code: "BO",
    },
    {
        name: "Brazil +55",
        dial_code: "+55",
        code: "BR",
    },
    {
        name: "Bahamas +1242",
        dial_code: "+1242",
        code: "BS",
    },
    {
        name: "Bhutan +975",
        dial_code: "+975",
        code: "BT",
    },
    {
        name: "Botswana +267",
        dial_code: "+267",
        code: "BW",
    },
    {
        name: "Belarus +375",
        dial_code: "+375",
        code: "BY",
    },
    {
        name: "Belize +501",
        dial_code: "+501",
        code: "BZ",
    },
    {
        name: "Canada +1",
        dial_code: "+1",
        code: "CA",
    },
    {
        name: "Cocos (Keeling) Islands +61",
        dial_code: "+61",
        code: "CC",
    },
    {
        name: "Congo, The Democratic Republic of the Congo +243",
        dial_code: "+243",
        code: "CD",
    },
    {
        name: "Central African Republic +236",
        dial_code: "+236",
        code: "CF",
    },
    {
        name: "Congo +242",
        dial_code: "+242",
        code: "CG",
    },
    {
        name: "Switzerland +41",
        dial_code: "+41",
        code: "CH",
    },
    {
        name: "Cote d'Ivoire +225",
        dial_code: "+225",
        code: "CI",
    },
    {
        name: "Cook Islands +682",
        dial_code: "+682",
        code: "CK",
    },
    {
        name: "Chile +56",
        dial_code: "+56",
        code: "CL",
    },
    {
        name: "Cameroon +237",
        dial_code: "+237",
        code: "CM",
    },
    {
        name: "China +86",
        dial_code: "+86",
        code: "CN",
    },
    {
        name: "Colombia +57",
        dial_code: "+57",
        code: "CO",
    },
    {
        name: "Costa Rica +506",
        dial_code: "+506",
        code: "CR",
    },
    {
        name: "Cuba +53",
        dial_code: "+53",
        code: "CU",
    },
    {
        name: "Cape Verde +238",
        dial_code: "+238",
        code: "CV",
    },
    {
        name: "Christmas Island +61",
        dial_code: "+61",
        code: "CX",
    },
    {
        name: "Cyprus +357",
        dial_code: "+357",
        code: "CY",
    },
    {
        name: "Czech Republic +420",
        dial_code: "+420",
        code: "CZ",
    },
    {
        name: "Germany +49",
        dial_code: "+49",
        code: "DE",
    },
    {
        name: "Djibouti +253",
        dial_code: "+253",
        code: "DJ",
    },
    {
        name: "Denmark +45",
        dial_code: "+45",
        code: "DK",
    },
    {
        name: "Dominica +1767",
        dial_code: "+1767",
        code: "DM",
    },
    {
        name: "Dominican Republic +1849",
        dial_code: "+1849",
        code: "DO",
    },
    {
        name: "Algeria +213",
        dial_code: "+213",
        code: "DZ",
    },
    {
        name: "Ecuador +593",
        dial_code: "+593",
        code: "EC",
    },
    {
        name: "Estonia +372",
        dial_code: "+372",
        code: "EE",
    },
    {
        name: "Egypt +20",
        dial_code: "+20",
        code: "EG",
    },
    {
        name: "Eritrea +291",
        dial_code: "+291",
        code: "ER",
    },
    {
        name: "Spain +34",
        dial_code: "+34",
        code: "ES",
    },
    {
        name: "Ethiopia +251",
        dial_code: "+251",
        code: "ET",
    },
    {
        name: "Finland +358",
        dial_code: "+358",
        code: "FI",
    },
    {
        name: "Fiji +679",
        dial_code: "+679",
        code: "FJ",
    },
    {
        name: "Falkland Islands (Malvinas) +500",
        dial_code: "+500",
        code: "FK",
    },
    {
        name: "Micronesia, Federated States of Micronesia +691",
        dial_code: "+691",
        code: "FM",
    },
    {
        name: "Faroe Islands +298",
        dial_code: "+298",
        code: "FO",
    },
    {
        name: "France +33",
        dial_code: "+33",
        code: "FR",
    },
    {
        name: "Gabon +241",
        dial_code: "+241",
        code: "GA",
    },
    {
        name: "United Kingdom +44",
        dial_code: "+44",
        code: "GB",
    },
    {
        name: "Grenada +1473",
        dial_code: "+1473",
        code: "GD",
    },
    {
        name: "Georgia +995",
        dial_code: "+995",
        code: "GE",
    },
    {
        name: "French Guiana +594",
        dial_code: "+594",
        code: "GF",
    },
    {
        name: "Guernsey +44",
        dial_code: "+44",
        code: "GG",
    },
    {
        name: "Ghana +233",
        dial_code: "+233",
        code: "GH",
    },
    {
        name: "Gibraltar +350",
        dial_code: "+350",
        code: "GI",
    },
    {
        name: "Greenland +299",
        dial_code: "+299",
        code: "GL",
    },
    {
        name: "Gambia +220",
        dial_code: "+220",
        code: "GM",
    },
    {
        name: "Guinea +224",
        dial_code: "+224",
        code: "GN",
    },
    {
        name: "Guadeloupe +590",
        dial_code: "+590",
        code: "GP",
    },
    {
        name: "Equatorial Guinea +240",
        dial_code: "+240",
        code: "GQ",
    },
    {
        name: "Greece +30",
        dial_code: "+30",
        code: "GR",
    },
    {
        name: "South Georgia and the South Sandwich Islands +500",
        dial_code: "+500",
        code: "GS",
    },
    {
        name: "Guatemala +502",
        dial_code: "+502",
        code: "GT",
    },
    {
        name: "Guam +1671",
        dial_code: "+1671",
        code: "GU",
    },
    {
        name: "Guinea-Bissau +245",
        dial_code: "+245",
        code: "GW",
    },
    {
        name: "Guyana +595",
        dial_code: "+595",
        code: "GY",
    },
    {
        name: "Hong Kong +852",
        dial_code: "+852",
        code: "HK",
    },
    {
        name: "Honduras +504",
        dial_code: "+504",
        code: "HN",
    },
    {
        name: "Croatia +385",
        dial_code: "+385",
        code: "HR",
    },
    {
        name: "Haiti +509",
        dial_code: "+509",
        code: "HT",
    },
    {
        name: "Hungary +36",
        dial_code: "+36",
        code: "HU",
    },
    {
        name: "Indonesia +62",
        dial_code: "+62",
        code: "ID",
    },
    {
        name: "Ireland +353",
        dial_code: "+353",
        code: "IE",
    },
    {
        name: "Israel +972",
        dial_code: "+972",
        code: "IL",
    },
    {
        name: "Isle of Man +44",
        dial_code: "+44",
        code: "IM",
    },
    {
        name: "India +91",
        dial_code: "+91",
        code: "IN",
    },
    {
        name: "British Indian Ocean Territory +246",
        dial_code: "+246",
        code: "IO",
    },
    {
        name: "Iraq +964",
        dial_code: "+964",
        code: "IQ",
    },
    {
        name: "Iran, Islamic Republic of Persian Gulf +98",
        dial_code: "+98",
        code: "IR",
    },
    {
        name: "Iceland +354",
        dial_code: "+354",
        code: "IS",
    },
    {
        name: "Italy +39",
        dial_code: "+39",
        code: "IT",
    },
    {
        name: "Jersey +44",
        dial_code: "+44",
        code: "JE",
    },
    {
        name: "Jamaica +1876",
        dial_code: "+1876",
        code: "JM",
    },
    {
        name: "Jordan +962",
        dial_code: "+962",
        code: "JO",
    },
    {
        name: "Japan +81",
        dial_code: "+81",
        code: "JP",
    },
    {
        name: "Kenya +254",
        dial_code: "+254",
        code: "KE",
    },
    {
        name: "Kyrgyzstan +996",
        dial_code: "+996",
        code: "KG",
    },
    {
        name: "Cambodia +855",
        dial_code: "+855",
        code: "KH",
    },
    {
        name: "Kiribati +686",
        dial_code: "+686",
        code: "KI",
    },
    {
        name: "Comoros +269",
        dial_code: "+269",
        code: "KM",
    },
    {
        name: "Saint Kitts and Nevis +1869",
        dial_code: "+1869",
        code: "KN",
    },
    {
        name: "Korea, Democratic People's Republic of Korea +850",
        dial_code: "+850",
        code: "KP",
    },
    {
        name: "Korea, Republic of South Korea +82",
        dial_code: "+82",
        code: "KR",
    },
    {
        name: "Kuwait +965",
        dial_code: "+965",
        code: "KW",
    },
    {
        name: "Cayman Islands  345",
        dial_code: "+ 345",
        code: "KY",
    },
    {
        name: "Kazakhstan +77",
        dial_code: "+77",
        code: "KZ",
    },
    {
        name: "Laos +856",
        dial_code: "+856",
        code: "LA",
    },
    {
        name: "Lebanon +961",
        dial_code: "+961",
        code: "LB",
    },
    {
        name: "Saint Lucia +1758",
        dial_code: "+1758",
        code: "LC",
    },
    {
        name: "Liechtenstein +423",
        dial_code: "+423",
        code: "LI",
    },
    {
        name: "Sri Lanka +94",
        dial_code: "+94",
        code: "LK",
    },
    {
        name: "Liberia +231",
        dial_code: "+231",
        code: "LR",
    },
    {
        name: "Lesotho +266",
        dial_code: "+266",
        code: "LS",
    },
    {
        name: "Lithuania +370",
        dial_code: "+370",
        code: "LT",
    },
    {
        name: "Luxembourg +352",
        dial_code: "+352",
        code: "LU",
    },
    {
        name: "Latvia +371",
        dial_code: "+371",
        code: "LV",
    },
    {
        name: "Libyan Arab Jamahiriya +218",
        dial_code: "+218",
        code: "LY",
    },
    {
        name: "Morocco +212",
        dial_code: "+212",
        code: "MA",
    },
    {
        name: "Monaco +377",
        dial_code: "+377",
        code: "MC",
    },
    {
        name: "Moldova +373",
        dial_code: "+373",
        code: "MD",
    },
    {
        name: "Montenegro +382",
        dial_code: "+382",
        code: "ME",
    },
    {
        name: "Saint Martin +590",
        dial_code: "+590",
        code: "MF",
    },
    {
        name: "Madagascar +261",
        dial_code: "+261",
        code: "MG",
    },
    {
        name: "Marshall Islands +692",
        dial_code: "+692",
        code: "MH",
    },
    {
        name: "Macedonia +389",
        dial_code: "+389",
        code: "MK",
    },
    {
        name: "Mali +223",
        dial_code: "+223",
        code: "ML",
    },
    {
        name: "Myanmar +95",
        dial_code: "+95",
        code: "MM",
    },
    {
        name: "Mongolia +976",
        dial_code: "+976",
        code: "MN",
    },
    {
        name: "Macao +853",
        dial_code: "+853",
        code: "MO",
    },
    {
        name: "Northern Mariana Islands +1670",
        dial_code: "+1670",
        code: "MP",
    },
    {
        name: "Martinique +596",
        dial_code: "+596",
        code: "MQ",
    },
    {
        name: "Mauritania +222",
        dial_code: "+222",
        code: "MR",
    },
    {
        name: "Montserrat +1664",
        dial_code: "+1664",
        code: "MS",
    },
    {
        name: "Malta +356",
        dial_code: "+356",
        code: "MT",
    },
    {
        name: "Mauritius +230",
        dial_code: "+230",
        code: "MU",
    },
    {
        name: "Maldives +960",
        dial_code: "+960",
        code: "MV",
    },
    {
        name: "Malawi +265",
        dial_code: "+265",
        code: "MW",
    },
    {
        name: "Mexico +52",
        dial_code: "+52",
        code: "MX",
    },
    {
        name: "Malaysia +60",
        dial_code: "+60",
        code: "MY",
    },
    {
        name: "Mozambique +258",
        dial_code: "+258",
        code: "MZ",
    },
    {
        name: "Namibia +264",
        dial_code: "+264",
        code: "NA",
    },
    {
        name: "New Caledonia +687",
        dial_code: "+687",
        code: "NC",
    },
    {
        name: "Niger +227",
        dial_code: "+227",
        code: "NE",
    },
    {
        name: "Norfolk Island +672",
        dial_code: "+672",
        code: "NF",
    },
    {
        name: "Nigeria +234",
        dial_code: "+234",
        code: "NG",
    },
    {
        name: "Nicaragua +505",
        dial_code: "+505",
        code: "NI",
    },
    {
        name: "Netherlands +31",
        dial_code: "+31",
        code: "NL",
    },
    {
        name: "Norway +47",
        dial_code: "+47",
        code: "NO",
    },
    {
        name: "Nepal +977",
        dial_code: "+977",
        code: "NP",
    },
    {
        name: "Nauru +674",
        dial_code: "+674",
        code: "NR",
    },
    {
        name: "Niue +683",
        dial_code: "+683",
        code: "NU",
    },
    {
        name: "New Zealand +64",
        dial_code: "+64",
        code: "NZ",
    },
    {
        name: "Oman +968",
        dial_code: "+968",
        code: "OM",
    },
    {
        name: "Panama +507",
        dial_code: "+507",
        code: "PA",
    },
    {
        name: "Peru +51",
        dial_code: "+51",
        code: "PE",
    },
    {
        name: "French Polynesia +689",
        dial_code: "+689",
        code: "PF",
    },
    {
        name: "Papua New Guinea +675",
        dial_code: "+675",
        code: "PG",
    },
    {
        name: "Philippines +63",
        dial_code: "+63",
        code: "PH",
    },
    {
        name: "Pakistan +92",
        dial_code: "+92",
        code: "PK",
    },
    {
        name: "Poland +48",
        dial_code: "+48",
        code: "PL",
    },
    {
        name: "Saint Pierre and Miquelon +508",
        dial_code: "+508",
        code: "PM",
    },
    {
        name: "Pitcairn +872",
        dial_code: "+872",
        code: "PN",
    },
    {
        name: "Puerto Rico +1939",
        dial_code: "+1939",
        code: "PR",
    },
    {
        name: "Palestinian Territory, Occupied +970",
        dial_code: "+970",
        code: "PS",
    },
    {
        name: "Portugal +351",
        dial_code: "+351",
        code: "PT",
    },
    {
        name: "Palau +680",
        dial_code: "+680",
        code: "PW",
    },
    {
        name: "Paraguay +595",
        dial_code: "+595",
        code: "PY",
    },
    {
        name: "Qatar +974",
        dial_code: "+974",
        code: "QA",
    },
    {
        name: "Reunion +262",
        dial_code: "+262",
        code: "RE",
    },
    {
        name: "Romania +40",
        dial_code: "+40",
        code: "RO",
    },
    {
        name: "Serbia +381",
        dial_code: "+381",
        code: "RS",
    },
    {
        name: "Russia +7",
        dial_code: "+7",
        code: "RU",
    },
    {
        name: "Rwanda +250",
        dial_code: "+250",
        code: "RW",
    },
    {
        name: "Saudi Arabia +966",
        dial_code: "+966",
        code: "SA",
    },
    {
        name: "Solomon Islands +677",
        dial_code: "+677",
        code: "SB",
    },
    {
        name: "Seychelles +248",
        dial_code: "+248",
        code: "SC",
    },
    {
        name: "Sudan +249",
        dial_code: "+249",
        code: "SD",
    },
    {
        name: "Sweden +46",
        dial_code: "+46",
        code: "SE",
    },
    {
        name: "Singapore +65",
        dial_code: "+65",
        code: "SG",
    },
    {
        name: "Saint Helena, Ascension and Tristan Da Cunha +290",
        dial_code: "+290",
        code: "SH",
    },
    {
        name: "Slovenia +386",
        dial_code: "+386",
        code: "SI",
    },
    {
        name: "Svalbard and Jan Mayen +47",
        dial_code: "+47",
        code: "SJ",
    },
    {
        name: "Slovakia +421",
        dial_code: "+421",
        code: "SK",
    },
    {
        name: "Sierra Leone +232",
        dial_code: "+232",
        code: "SL",
    },
    {
        name: "San Marino +378",
        dial_code: "+378",
        code: "SM",
    },
    {
        name: "Senegal +221",
        dial_code: "+221",
        code: "SN",
    },
    {
        name: "Somalia +252",
        dial_code: "+252",
        code: "SO",
    },
    {
        name: "Suriname +597",
        dial_code: "+597  code:",
        code: "SR",
    },
    {
        name: "South Sudan +211",
        dial_code: "+211",
        code: "SS",
    },
    {
        name: "Sao Tome and Principe +239",
        dial_code: "+239",
        code: "ST",
    },
    {
        name: "El Salvador +503",
        dial_code: "+503",
        code: "SV",
    },
    {
        name: "Syrian Arab Republic +963",
        dial_code: "+963",
        code: "SY",
    },
    {
        name: "Swaziland +268",
        dial_code: "+268",
        code: "SZ",
    },
    {
        name: "Turks and Caicos Islands +1649",
        dial_code: "+1649",
        code: "TC",
    },
    {
        name: "Chad +235",
        dial_code: "+235",
        code: "TD",
    },
    {
        name: "Togo +228",
        dial_code: "+228",
        code: "TG",
    },
    {
        name: "Thailand +66",
        dial_code: "+66",
        code: "TH",
    },
    {
        name: "Tajikistan +992",
        dial_code: "+992",
        code: "TJ",
    },
    {
        name: "Tokelau +690",
        dial_code: "+690",
        code: "TK",
    },
    {
        name: "Timor-Leste +670",
        dial_code: "+670",
        code: "TL",
    },
    {
        name: "Turkmenistan +993",
        dial_code: "+993",
        code: "TM",
    },
    {
        name: "Tunisia +216",
        dial_code: "+216",
        code: "TN",
    },
    {
        name: "Tonga +676",
        dial_code: "+676",
        code: "TO",
    },
    {
        name: "Turkey +90",
        dial_code: "+90",
        code: "TR",
    },
    {
        name: "Trinidad and Tobago +1868",
        dial_code: "+1868",
        code: "TT",
    },
    {
        name: "Tuvalu +688",
        dial_code: "+688",
        code: "TV",
    },
    {
        name: "Taiwan +886",
        dial_code: "+886",
        code: "TW",
    },
    {
        name: "Tanzania, United Republic of Tanzania +255",
        dial_code: "+255",
        code: "TZ",
    },
    {
        name: "Ukraine +380",
        dial_code: "+380",
        code: "UA",
    },
    {
        name: "Uganda +256",
        dial_code: "+256",
        code: "UG",
    },
    {
        name: "United States +1",
        dial_code: "+1",
        code: "US",
    },
    {
        name: "Uruguay +598",
        dial_code: "+598",
        code: "UY",
    },
    {
        name: "Uzbekistan +998",
        dial_code: "+998",
        code: "UZ",
    },
    {
        name: "Holy See (Vatican City State) +379",
        dial_code: "+379",
        code: "VA",
    },
    {
        name: "Saint Vincent and the Grenadines +1784",
        dial_code: "+1784",
        code: "VC",
    },
    {
        name: "Venezuela, Bolivarian Republic of Venezuela +58",
        dial_code: "+58",
        code: "VE",
    },
    {
        name: "Virgin Islands, British +1284",
        dial_code: "+1284",
        code: "VG",
    },
    {
        name: "Virgin Islands, U.S. +1340",
        dial_code: "+1340",
        code: "VI",
    },
    {
        name: "Vietnam +84",
        dial_code: "+84",
        code: "VN",
    },
    {
        name: "Vanuatu +678",
        dial_code: "+678",
        code: "VU",
    },
    {
        name: "Wallis and Futuna +681",
        dial_code: "+681",
        code: "WF",
    },
    {
        name: "Samoa +685",
        dial_code: "+685",
        code: "WS",
    },
    {
        name: "Yemen +967",
        dial_code: "+967",
        code: "YE",
    },
    {
        name: "Mayotte +262",
        dial_code: "+262",
        code: "YT",
    },
    {
        name: "South Africa +27",
        dial_code: "+27",
        code: "ZA",
    },
    {
        name: "Zambia +260",
        dial_code: "+260",
        code: "ZM",
    },
    {
        name: "Zimbabwe +263",
        dial_code: "+263",
        code: "ZW",
    },
];

module.exports=countryCodeArray